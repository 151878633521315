import React, { useEffect, useRef } from "react";
import styled from "styled-components/macro";
import { NavLink, useHistory } from "react-router-dom";

import { Helmet } from "react-helmet-async";

import {
  CardContent,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  Box,
} from "@material-ui/core";

import { spacing } from "@material-ui/system";
import { useApp } from "../../../AppProvider";
import Loader from "../../../components/Loader";
import Button from "@material-ui/core/Button";
import { useMutation, useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import MaterialTable, { MTableToolbar } from "material-table";
import { sleep } from "../../../components/Async";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const FormContents = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 16px 0;
`;

const Legend = styled.div`
  align-items: center;
  display: flex;
  gap: 24px;
  margin-bottom: 16px;
`;

const LegendItem = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`;

const LegendItemBox = styled.div`
  background-color: ${(props) => props.bgColor || "white"};
  border: 1px solid #aaa;
  border-radius: 2px;
  height: 20px;
  width: 48px;
`;

const QuickFiltersContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`;

const today = new Date();
const todayShort = today.toISOString().split("T")[0];

const last14Days = new Date();
last14Days.setDate(last14Days.getDate() - 14);
const last14DaysShort = last14Days.toISOString().split("T")[0];

const last31Days = new Date();
last31Days.setDate(last31Days.getDate() - 31);
const last31DaysShort = last31Days.toISOString().split("T")[0];

const last92Days = new Date();
last92Days.setDate(last92Days.getDate() - 92);
const last92DaysShort = last92Days.toISOString().split("T")[0];

const startOfMonth = new Date();
startOfMonth.setDate(1);
const startOfMonthShort = startOfMonth.toISOString().split("T")[0];

const endOfMonth = new Date();
endOfMonth.setMonth(endOfMonth.getMonth() + 1);
endOfMonth.setDate(0);
const endOfMonthShort = endOfMonth.toISOString().split("T")[0];

const startOfLastMonth = new Date();
startOfLastMonth.setMonth(startOfLastMonth.getMonth() - 1);
startOfLastMonth.setDate(1);
const startOfLastMonthShort = startOfLastMonth.toISOString().split("T")[0];

const endOfLastMonth = new Date();
endOfLastMonth.setDate(0);
const endOfLastMonthShort = endOfLastMonth.toISOString().split("T")[0];

const DEFAULT_START_DATE = new Date();
DEFAULT_START_DATE.setDate(DEFAULT_START_DATE.getDate() - 6);

const DATES_BY_PERIOD = {
  "last-14-days": {
    start: last14DaysShort,
    end: todayShort,
  },
  "last-31-days": {
    start: last31DaysShort,
    end: todayShort,
  },
  "last-92-days": {
    start: last92DaysShort,
    end: todayShort,
  },
  "this-month": {
    start: startOfMonthShort,
    end: endOfMonthShort,
  },
  "last-month": {
    start: startOfLastMonthShort,
    end: endOfLastMonthShort,
  },
};

const BASE_URL = "/water-accounting/poudre-ponds/daily-accounting";

function getQuickFilterUrl(value, accountingColumnGroup) {
  const dates = DATES_BY_PERIOD[value];
  if (!dates) return BASE_URL;
  return `${BASE_URL}?start_date=${dates.start}&end_date=${dates.end}&accounting_column_group=${accountingColumnGroup}`;
}

const RESET_TIMEOUT = 150;
const REFRESH_TIMEOUT = 1000;

function Filters({ handleSubmit, endDate, startDate, accountingColumnGroup }) {
  const { getAccessTokenSilently } = useAuth0();

  const { data: colGroupsData, isLoading: isColGroupsLoading } = useQuery(
    ["accounting-col-groups"],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/list-pp-accounting-column-groups`,
          { headers }
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  return (
    <form onSubmit={handleSubmit}>
      <FormContents>
        <TextField
          defaultValue={startDate}
          type={"date"}
          label={"Start Date"}
          name={"start_date"}
          variant={"outlined"}
          value={undefined}
        />
        <TextField
          defaultValue={endDate}
          type={"date"}
          label={"End Date"}
          name={"end_date"}
          variant={"outlined"}
        />
        <FormControl variant="outlined" style={{ width: "300px" }} required>
          {isColGroupsLoading ? (
            <Loader />
          ) : (
            <>
              <InputLabel id="acounting_column_group">
                Accounting Column Groups
              </InputLabel>
              <Select
                key={accountingColumnGroup}
                labelId="acounting_column_group"
                id="acounting_column_group"
                label="Accounting Column Groups"
                name="acounting_column_group"
                defaultValue={accountingColumnGroup}
              >
                {colGroupsData?.map((option) => (
                  <MenuItem
                    key={option.col_group_name}
                    value={option.col_group_ndx}
                  >
                    {option.col_group_name}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          type={"submit"}
          style={{ width: "170px" }}
        >
          Submit
        </Button>
      </FormContents>
    </form>
  );
}

function AccountingTable({
  columns = [],
  data,
  isLoading,
  isSaving,
  handleSave,
}) {
  const tableContainerRef = useRef();

  /**
   * We follow a similar approach to the useEffect where we access the Material Table
   * actions prop through the ref to get access to the onClick handlers for the
   * Save All Changes and Edit All actions
   * This allows us to use our own custom submit button below the table.
   */
  async function handleSubmit(event) {
    event.preventDefault();
    const saveEditsAction = tableContainerRef?.current?.props?.actions?.find(
      (action) => action.tooltip === "Save all changes"
    );
    const editAllAction = tableContainerRef?.current?.props?.actions?.find(
      (action) => action.tooltip === "Edit All"
    );

    saveEditsAction?.onClick();
    // await handleRefresh();

    // We need to bake in a little extra waiting time for the data to refresh
    // Because the update runs quickly but we have no way of guaranteeing how
    // long the trigger function on daily_pp_accounting_editing_table will take
    // it usually takes less than 800 ms but we'll wait 1 second to be safe
    await sleep(REFRESH_TIMEOUT);

    // Switch table back into edit mode
    editAllAction?.onClick();
  }

  /**
   * We follow a similar approach to the useEffect where we access the Material Table
   * actions prop through the ref to get access to the onClick handlers for the
   * Reset All Changes and Edit All actions
   * This allows us to use our own custom submit button below the table.
   */
  async function handleReset() {
    const discardEditsAction = tableContainerRef?.current?.props?.actions?.find(
      (action) => action.tooltip === "Discard all changes"
    );
    const editAllAction = tableContainerRef?.current?.props?.actions?.find(
      (action) => action.tooltip === "Edit All"
    );

    discardEditsAction?.onClick();

    await sleep(RESET_TIMEOUT);

    // Switch table back into edit mode
    editAllAction?.onClick();
  }

  // Jank but the only way to initialize the Material Table in edit mode
  // We get access to the action onClick handlers through the ref and can use
  // that here to simulate clicking on the Edit All icon in the UI
  useEffect(() => {
    async function toggleMode() {
      const editAllAction = tableContainerRef?.current?.props?.actions?.find(
        (action) => action.tooltip === "Edit All"
      );
      const discardEditsAction =
        tableContainerRef?.current?.props?.actions?.find(
          (action) => action.tooltip === "Discard all changes"
        );
      discardEditsAction?.onClick();
      await sleep(RESET_TIMEOUT);
      editAllAction.onClick();
    }

    // This ensures that whenever the underlying data changes, we reinitialize the table
    // Without this, the table UI will not update after the user submits new date filter selections
    if (data?.length) {
      toggleMode();
    }
  }, [tableContainerRef, data]);

  return (
    <form onSubmit={handleSubmit}>
      <MaterialTable
        components={{
          Toolbar: (props) => {
            /**
             * We need to render the Material Table Toolbar component
             * so we can get access to it through the ref. This allows us to
             * programmatically do things like initialize the table in edit mode
             * and use the save/reset handlers outside of the Table UI
             * We hide the toolbar to save the vertical real estate and since
             * we are using our own, more obvious submit/reset buttons
             */
            return (
              <div style={{ visibility: "hidden", height: 0 }}>
                <MTableToolbar {...props} ref={tableContainerRef} />
              </div>
            );
          },
        }}
        editable={{
          isEditHidden: () => true,
          isDeleteHidden: () => true,
          isDeletable: () => false,
          isEditable: () => true,
          onBulkUpdate: async (changes) => {
            const parsedChanges = Object.values(changes).map((change) => {
              return change.newData;
            });

            return handleSave(parsedChanges);
          },
        }}
        columns={columns?.map((col) => ({ ...col }))}
        isLoading={isLoading}
        data={data}
        options={{
          // TODO investigate further
          // Wish we could do use this but it makes the table fixed width
          // and throws everything else off
          // fixedColumns: {
          //   left: 1,
          // },
          actionsColumnIndex: -1, // This is to "hide" the actions column aka put far right
          emptyRowsWhenPaging: false,
          pageSizeOptions: [14, 30, 90],
          pageSize: 14,
          showTitle: false,
          search: false,
        }}
      />
      <Box mt={4} display={"flex"}>
        <Button
          disabled={isSaving}
          variant="contained"
          color="primary"
          type={"submit"}
          style={{ marginRight: "8px" }}
        >
          Submit
        </Button>
        <Button variant="contained" onClick={handleReset}>
          Reset
        </Button>
      </Box>
    </form>
  );
}

function PoudrePonds234() {
  const history = useHistory();
  const searchParams = new URLSearchParams(window.location.search);
  const startDate =
    searchParams.get("start_date") || DATES_BY_PERIOD["last-14-days"].start;
  const endDate =
    searchParams.get("end_date") || DATES_BY_PERIOD["last-14-days"].end;
  const accountingColumnGroup =
    searchParams.get("accounting_column_group") || 1;

  const { currentUser } = useApp();
  const { getAccessTokenSilently } = useAuth0();
  const { doToast } = useApp();

  const { data, isLoading, refetch } = useQuery(
    ["accounting-data", startDate, endDate],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/daily-pp-accounting-editing-table/${startDate}/${endDate}`,
          { headers }
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );

  const { data: columns } = useQuery(
    ["accounting-columns-data", accountingColumnGroup],
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const headers = { Authorization: `Bearer ${token}` };

        const { data } = await axios.get(
          `${process.env.REACT_APP_ENDPOINT}/api/list-pp-accounting-all-fields/column-defs/${accountingColumnGroup}`,
          { headers }
        );
        return data;
      } catch (err) {
        console.error(err);
      }
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { mutate, isLoading: isSaving } = useMutation(
    async (formData) => {
      if (!formData?.length) return;

      const url = `${process.env.REACT_APP_ENDPOINT}/api/daily-pp-accounting-editing-table`;

      const token = await getAccessTokenSilently();
      const headers = { Authorization: `Bearer ${token}` };

      try {
        return axios.put(url, formData, {
          headers,
        });
      } catch (error) {
        throw error;
      }
    },
    {
      onSuccess: async () => {
        await refetch();
        doToast("success", "Values successfully saved.");
      },
      onError: (err) => {
        doToast("error", "Failed to save values.");
        console.error(err);
      },
    }
  );

  async function handleSubmitFilters(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    history.push(
      `${BASE_URL}?start_date=${data.start_date}&end_date=${data.end_date}&accounting_column_group=${data.acounting_column_group}`
    );
  }

  /**
   * This is a less than desirable workaround to deal with flaws in the material-table
   * library that results in excessive re-rendering and crashing the browser when
   * the underlying table data/columns change
   * We are required to do a full page reload to prevent the page crash
   */
  function handleQuickFilterClick(event) {
    const buttonPeriod = event.target?.closest("button")?.name;
    history.push(getQuickFilterUrl(buttonPeriod, accountingColumnGroup));
    window.location.reload();
  }

  return (
    <React.Fragment>
      <Helmet title="Poudre Ponds Daily Accounting (Case 99CW234)" />
      <Typography variant="h3" gutterBottom display="inline">
        Poudre Ponds Daily Accounting (Case 99CW234)
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} exact to={currentUser?.home || "/"}>
          Dashboard
        </Link>
        <Typography>Poudre Ponds Daily Accounting (Case 99CW234)</Typography>
      </Breadcrumbs>

      <Divider my={6} />
      <Card mb={6} p={2}>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            Step 1 - Select a Date Range and Columns to Edit
          </Typography>
          <Typography
            color={"textSecondary"}
            variant="body2"
            style={{ maxWidth: 600, marginBottom: "8px" }}
          >
            Use the following table to edit the accounting records for the
            selected date range and columns.
          </Typography>
          <Typography
            color={"textSecondary"}
            variant="body2"
            style={{ maxWidth: 600, marginBottom: "24px", fontWeight: "bold" }}
          >
            Please note that pending changes will be lost when the selected data
            range filters are updated . Make sure to save your edits first.
          </Typography>
          <Filters
            handleSubmit={handleSubmitFilters}
            startDate={startDate}
            endDate={endDate}
            accountingColumnGroup={accountingColumnGroup}
          />
          <Typography
            variant="h6"
            style={{ maxWidth: 600, marginBottom: "16px" }}
          >
            Quick Date Filters
          </Typography>
          <QuickFiltersContainer>
            <Button
              disabled={isLoading}
              value={"text"}
              variant={"outlined"}
              onClick={handleQuickFilterClick}
              name={"last-14-days"}
            >
              Last 14 Days
            </Button>
            <Button
              disabled={isLoading}
              value={"text"}
              variant={"outlined"}
              onClick={handleQuickFilterClick}
              name={"last-31-days"}
            >
              Last 31 Days
            </Button>
            <Button
              disabled={isLoading}
              value={"text"}
              variant={"outlined"}
              onClick={handleQuickFilterClick}
              name={"last-92-days"}
            >
              Last 92 Days
            </Button>
            <Button
              disabled={isLoading}
              value={"text"}
              variant={"outlined"}
              onClick={handleQuickFilterClick}
              name={"this-month"}
            >
              This Month
            </Button>
            <Button
              disabled={isLoading}
              value={"text"}
              variant={"outlined"}
              onClick={handleQuickFilterClick}
              name={"last-month"}
            >
              Last Month
            </Button>
          </QuickFiltersContainer>
          <Divider my={6} />

          <Typography variant="h5" gutterBottom>
            Step 2 - Select a Date Range and Columns to Edit
          </Typography>
          <Typography
            variant="body2"
            style={{ maxWidth: 600, marginBottom: "16px" }}
          >
            Use the following table to edit the accounting records for the
            selected date range and columns
          </Typography>

          <Legend>
            <LegendItem>
              <LegendItemBox bgColor={"#fff7e1"} />
              <Typography variant="body2">Editable Field</Typography>
            </LegendItem>
            <LegendItem>
              <LegendItemBox bgColor={"#eefdff"} />
              <Typography variant="body2">Telemetry Field</Typography>
            </LegendItem>
          </Legend>
          <AccountingTable
            columns={columns}
            data={data}
            isLoading={isLoading}
            isSaving={isSaving}
            handleSave={mutate}
          />
        </CardContent>
      </Card>
    </React.Fragment>
  );
}

export default PoudrePonds234;
